:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6358dc;
  --bs-purple-hover: #574ccb;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #16bb58;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-light-blue: #def3ff;
  --bs-primary: #6358dc;
  --bs-primary-femalesandfinance: #cd007a;
  --bs-primary-hover: #574ccb;
  --bs-secondary: #07223f;
  --bs-secondary-hover: #051d36;
  --bs-success: #53bb3a;
  --bs-success-hover: #47a630;
  --bs-info: #7fcfd6;
  --bs-info-hover: #70c2c4;
  --bs-warning: #ffc107;
  --bs-danger: #f23b3b;
  --bs-danger-hover: #dd2c2c;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 99, 88, 220;
  --bs-primary-hover-rgb: 87, 76, 203;
  --bs-secondary-rgb: 7, 34, 63;
  --bs-secondary-hover-rgb: 5, 29, 54;
  --bs-success-rgb: 83, 187, 58;
  --bs-success-hover-rgb: 71, 166, 48;
  --bs-info-rgb: 127, 207, 214;
  --bs-info-hover-rgb: 112, 194, 196;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 242, 59, 59;
  --bs-danger-hover-rgb: 221, 44, 44;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  --bs-body-font-family: "Nunito", sans-serif;
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.375;
  --bs-body-color: #2d2d2d;
  --bs-body-bg: #e6e8f6;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dedede;
  --bs-border-color-translucent: #dedede;
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: var(--bs-primary);
  --bs-link-hover-color: var(--bs-primary-hover);
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
  --bs-section-bg: #d8e2e7;
}

html {
  font-size: 16px;
}

a,
.page-link {
  text-decoration: none;
  transition: all 0.15s ease-in-out;
}
a:hover,
.page-link:hover {
  color: var(--bs-primary-hover);
  text-decoration: none;
}
.page-link:focus {
  box-shadow: none;
}
img {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 700;
  color: #2d2d2d;
  margin-bottom: 1rem;
}
h1,
.h1 {
  font-size: 1.5rem;
  line-height: 1.875rem;
}
h2,
.h2 {
  font-size: 1.375rem;
  line-height: 1.625rem;
}
h3,
.h3 {
  font-size: 1.25rem;
  line-height: 1.5rem;
}
h4,
.h4 {
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.title-32 {
  font-size: 2rem;
  line-height: 2.25rem;
}

.fw-medium {
  font-weight: 500;
}
.fw-sbold {
  font-weight: 600;
}
.text-gray {
  color: #a1a1a1;
}

.text-red {
  color: red;
}

.text-dark-grey {
  color: #3c3d42;
}
.text-purple {
  color: var(--bs-purple);
}

.text-darkBlue {
  color: #3f51b5;
}
.bg-darkBlue {
  background-color: #3f51b5;
}

.step-by-step-header {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
  z-index: 3;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  border-bottom: 1px solid #ffffff;
}

.header {
  position: sticky;
  top: 0;
  z-index: 3;
  background: linear-gradient(
    90deg,
    rgba(235, 235, 242, 0.24) 0%,
    rgba(204, 201, 240, 0.48) 100%
  );
  backdrop-filter: blur(40px);
}

.navbar {
  --bs-navbar-padding-x: 0.5rem;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: var(--bs-secondary);
  --bs-navbar-hover-color: var(--bs-purple);
  --bs-navbar-disabled-color: #a1a1a1;
  --bs-navbar-active-color: var(--bs-purple);
  --bs-navbar-brand-padding-y: 0;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-nav-link-padding-x: 1.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke='%2307223F' stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: transparent;
  --bs-navbar-toggler-border-radius: 0.375rem;
  --bs-navbar-toggler-focus-width: 0;
  min-height: 2rem;
}

.navbar-nav {
  --bs-nav-link-padding-x: 0.5rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-size: 1rem;
  --bs-nav-link-font-weight: 500;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
}

.horizontal-dropdown {
  width: 100vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: linear-gradient(
    90deg,
    rgba(235, 235, 242, 1) 0%,
    rgba(204, 201, 240, 1) 100%
  );
  border: none;
  margin-top: 17px;
  z-index: 10000;
}

.horizontal-dropdown .dropdown-item {
  display: flex;
  padding: 10px 15px;
  flex: 1;
  text-align: center;
  border: none;
  transition: all 0.3s;
}

.horizontal-dropdown .dropdown-item:hover,
.horizontal-dropdown .dropdown-item:focus {
  text-decoration: underline;
  background-color: transparent;
  outline: none;
}

@keyframes rotate-up {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes rotate-down {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.rotate-up {
  animation: rotate-up 0.3s forwards;
}

.rotate-down {
  animation: rotate-down 0.3s forwards;
}

.page-content {
  padding: 1.875rem 0;
  position: relative;
  min-height: calc(100vh - 66px);
}
.h-in-content {
  min-height: calc(100vh - 140px);
}
.page-content:after,
.page-pattern-2:before,
.page-pattern-2:after,
.main-content:after {
  content: "";
  position: fixed;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: -1;
}
.page-pattern-2:before {
  left: 0;
  top: 0;
  background-image: url(/src/assets/image/pattern-2-lt.png);
  background-position: left top;
  background-size: 40% auto;
}
.page-pattern-2:after,
.main-content:after {
  left: 0;
  bottom: 0;
  background-image: url(/src/assets/image/pattern-2-rb.png);
  background-position: right bottom;
  background-size: 36% auto;
}
.page-title h1 {
  text-transform: uppercase;
  margin-bottom: 1.125rem;
}
.page-title h1 .small {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  text-transform: none;
  vertical-align: 3px;
}
.page-footer {
  padding: 0 0 1.5rem;
}

.main-content {
  position: relative;
  min-height: calc(100vh - 80px);
}
.offcanvas,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm,
.offcanvas-xl,
.offcanvas-xxl {
  --bs-offcanvas-width: 252px;
}
.sidebar-toggler {
  position: fixed;
  top: 0.95rem;
  right: 4.25rem;
  z-index: 4;
  padding: 0.25rem 0.75rem;
  line-height: 1;
  color: #07223f;
  background-color: transparent;
  border: none;
}
/*.sidebar {
  width: 252px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  padding-top: 80px;
  background-color: #fff;
}*/
.sidebar ul {
  padding-top: 2rem;
}
.sidebar ul li {
  width: 100%;
  padding: 0.5rem 2rem;
}
.sidebar ul li a {
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  color: #2d2d2d;
  padding: 0;
}
.sidebar ul li a .nav-span {
  display: flex;
  align-items: center;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  width: 100%;
}
.sidebar ul li.active a {
  color: var(--bs-primary);
}
.sidebar ul li a .icon {
  padding-right: 0.5rem;
  font-size: 1.375rem;
}
.sb-content {
  padding: 1.25rem;
}

.page-heading {
  padding: 1.25rem 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
}
.page-heading h1 {
  margin-bottom: 0.5rem;
}
.page-heading .text-sub {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: #a1a1a1;
}

.items-dash-info .item-text {
  font-size: 1.15rem;
  line-height: 1.875rem;
  font-weight: 600;
}

.items-dash {
  padding: 2.25rem 1.5rem;
  height: 100%;
  text-align: center;
  background-color: rgb(255, 255, 255);
  border-radius: 0.375rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.15);
}
.items-dash.disabled {
  opacity: 0.7;
  pointer-events: none;
}
.items-dash .item-icon {
  margin-bottom: 0.75rem;
}
.items-dash .item-icon img {
  width: 3rem;
  height: 3rem;
}
.info-icon {
  align-self: flex-end;
  margin-left: 0.125rem;
}

/* Buttons Style */
.btn {
  --bs-btn-padding-x: 1.5rem;
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-font-family: var(--bs-body-font-family);
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 600;
  --bs-btn-line-height: 1.5rem;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-border-radius: 0.25rem;
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0.375rem 0.625rem rgba(116, 116, 116, 0.3);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-sm {
  --bs-btn-padding-x: 1rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-line-height: 1.125rem;
}
.btn-shadow {
  box-shadow: 0 0.125rem 0.375rem rgba(116, 116, 116, 0.15);
}
.btn-shadow:hover {
  background-color: var(--bs-light);
}
.btn-icon .icon {
  width: 1.5rem;
  margin-right: 0.75rem;
}

.btn-lg {
  --bs-btn-padding-x: 1.5rem;
  --bs-btn-padding-y: 0.875rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-line-height: 1.875rem;
}

.w-80 {
  width: 76.5%;
  max-width: 100%;
}
.w-90 {
  width: 80%;
  max-width: 100%;
}
.w-110 {
  width: 110px;
  max-width: 100%;
}
.w-150 {
  width: 150px;
  max-width: 100%;
}
.w-220 {
  width: 220px;
  max-width: 100%;
}
.w-240 {
  width: 240px;
  max-width: 100%;
}
.w-280 {
  width: 280px;
  max-width: 100%;
}
.w-380 {
  width: 380px;
  max-width: 100%;
}
.w-480 {
  width: 480px;
  max-width: 100%;
}

.mt-100 {
  margin-top: 100px;
}

.mt-c-6 {
  margin-top: 2rem;
}

.link {
  font-weight: 700;
  text-decoration: none;
  background-color: transparent;
  border: none;
  padding: 0;
  color: var(--bs-primary-hover);
}
.link-primary,
.link-primary:focus {
  color: var(--bs-primary) !important;
}
.link-primary:hover {
  color: var(--bs-primary-hover) !important;
}

.text-container {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--bs-primary);
  --bs-btn-border-color: var(--bs-primary);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--bs-primary-hover);
  --bs-btn-hover-border-color: var(--bs-primary-hover);
  --bs-btn-focus-shadow-rgb: 116, 116, 116;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--bs-primary-hover);
  --bs-btn-active-border-color: var(--bs-primary-hover);
  --bs-btn-active-shadow: 0 0.375rem 0.625rem rgba(116, 116, 116, 0.3);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--bs-primary);
  --bs-btn-disabled-border-color: var(--bs-primary);
}

.btn-primary-femalesandfinance {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--bs-primary-femalesandfinance);
  --bs-btn-border-color: var(--bs-primary-femalesandfinance);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--bs-primary-femalesandfinance);
  --bs-btn-hover-border-color: var(--bs-primary-femalesandfinance);
  --bs-btn-focus-shadow-rgb: 116, 116, 116;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--bs-primary-femalesandfinance);
  --bs-btn-active-border-color: var(--bs-primary-femalesandfinance);
  --bs-btn-active-shadow: 0 0.375rem 0.625rem rgba(116, 116, 116, 0.3);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--bs-primary-femalesandfinance);
  --bs-btn-disabled-border-color: var(--bs-primary-femalesandfinance);
}

.btn-outline-primary {
  --bs-btn-color: var(--bs-primary);
  --bs-btn-bg: var(--bs-white);
  --bs-btn-border-color: var(--bs-primary);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: var(--bs-primary);
  --bs-btn-hover-border-color: var(--bs-primary);
  --bs-btn-focus-shadow-rgb: 116, 116, 116;
  --bs-btn-active-color: var(--bs-white);
  --bs-btn-active-bg: var(--bs-primary);
  --bs-btn-active-border-color: var(--bs-primary);
  --bs-btn-active-shadow: 0 0.625rem 1.25rem rgba(116, 116, 116, 0.1);
  --bs-btn-disabled-color: var(--bs-primary);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--bs-primary);
}

.btn-outline-primary.bg-transparent {
  --bs-btn-color: var(--bs-primary);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: var(--bs-primary);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: var(--bs-primary);
  --bs-btn-hover-border-color: var(--bs-primary);
  --bs-btn-focus-shadow-rgb: 116, 116, 116;
  --bs-btn-active-color: var(--bs-white);
  --bs-btn-active-bg: var(--bs-primary);
  --bs-btn-active-border-color: var(--bs-primary);
  --bs-btn-active-shadow: 0 0.625rem 1.25rem rgba(116, 116, 116, 0.1);
  --bs-btn-disabled-color: var(--bs-primary);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--bs-primary);
}
.btn-outline-primary.bg-transparent:hover {
  background-color: var(--bs-btn-hover-bg) !important;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--bs-secondary);
  --bs-btn-border-color: var(--bs-secondary);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--bs-secondary-hover);
  --bs-btn-hover-border-color: var(--bs-secondary-hover);
  --bs-btn-focus-shadow-rgb: 116, 116, 116;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--bs-secondary-hover);
  --bs-btn-active-border-color: var(--bs-purple-hover);
  --bs-btn-active-shadow: 0 0.375rem 0.625rem rgba(116, 116, 116, 0.3);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--bs-secondary);
  --bs-btn-disabled-border-color: var(--bs-secondary);
}

.btn-outline-secondary {
  --bs-btn-color: var(--bs-secondary);
  --bs-btn-bg: #fff;
  --bs-btn-border-color: var(--bs-secondary);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--bs-secondary);
  --bs-btn-hover-border-color: var(--bs-secondary);
  --bs-btn-focus-shadow-rgb: 116, 116, 116;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--bs-secondary);
  --bs-btn-active-border-color: var(--bs-secondary);
  --bs-btn-active-shadow: 0 0.625rem 1.25rem rgba(116, 116, 116, 0.1);
  --bs-btn-disabled-color: var(--bs-secondary);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--bs-secondary);
}

.btn-gray {
  background-color: #bfbfbf;
  color: white;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  border-radius: 0.25rem;
  border: none;
  width: max-content;
}

.form-label,
.MuiFormLabel-root.form-label {
  font-size: 1rem;
  line-height: 1.25rem;
  margin-bottom: 0.5rem;
  color: #000000;
}
.form-control,
.form-select {
  padding: 0.5rem 1rem;
  border-color: var(--bs-border-color);
  background-color: #fcfcfc;
  color: var(--bs-body-color);
  font-size: 1rem;
  font-family: var(--bs-body-font-family);
  font-weight: 400;
  line-height: 1.5rem;
}
.form-select {
  padding-right: 2.25rem;
}
.form-control-color {
  height: 2.625rem;
  padding: 0.5rem;
}
.form-control[type="file"]::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}
.form-control:focus,
.form-select:focus {
  border-color: var(--bs-primary);
  background-color: #fcfcfc;
  box-shadow: none;
}
.MuiFormControl-root
  .MuiInputBase-root
  .MuiInputBase-input[type="number"]::-webkit-outer-spin-button,
.MuiFormControl-root
  .MuiInputBase-root
  .MuiInputBase-input[type="number"]::-webkit-inner-spin-button,
.form-control[type="number"]::-webkit-outer-spin-button,
.form-control[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.form-control[type="number"],
.MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
  -moz-appearance: textfield;
}
.MuiFormControl-root {
  width: 100%;
}
.MuiFormControl-root .MuiInputBase-root,
.MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
  color: var(--bs-body-color);
  width: 100%;
  font-size: 1rem;
  font-family: var(--bs-body-font-family);
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0;
}
.MuiFormControl-root .MuiInputBase-root.MuiOutlinedInput-multiline {
  padding: 0;
}
.MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
  padding: 0.5rem 0.5rem;
  height: auto;
  border: 1px solid var(--bs-border-color);
  background-color: #fcfcfc;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.MuiFormControl-root.lg .MuiInputBase-root .MuiInputBase-input {
  padding: calc(1rem - 1px) 1.25rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 500;
}
.MuiFormControl-root .MuiInputBase-root .MuiInputBase-input:focus {
  border-color: var(--bs-primary);
}
.MuiFormControl-root .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  display: none;
}

.MuiFormControl-root .MuiInputBase-root.MuiInputBase-adornedStart {
  position: relative;
  padding-left: 0;
}
.MuiFormControl-root
  .MuiInputBase-root.MuiInputBase-adornedStart
  .MuiInputBase-input {
  padding-left: 2.5rem;
}
.MuiFormControl-root
  .MuiInputBase-root.MuiInputBase-adornedStart
  .MuiInputAdornment-root {
  position: absolute;
  left: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1.5rem;
  height: 1.5rem;
  color: #a1a1a1;
}

.css-b62m3t-container .css-13cymwt-control,
.css-b62m3t-container .css-13cymwt-control:hover,
.css-b62m3t-container .css-t3ipsp-control,
.css-b62m3t-container .css-t3ipsp-control:hover {
  border-color: var(--bs-border-color);
  background-color: #fcfcfc;
  box-shadow: none;
}
.css-b62m3t-container .css-t3ipsp-control:hover {
  border-color: var(--bs-primary);
}
.css-b62m3t-container .css-3w2yfm-ValueContainer {
  padding: 0.25rem;
}
.css-b62m3t-container .css-3w2yfm-ValueContainer .css-1p3m7a8-multiValue {
  margin: 0.25rem;
  background-color: #fcfcfc;
  border: 1px solid var(--bs-primary);
  align-items: center;
  border-radius: 0.25rem;
}
.css-b62m3t-container
  .css-3w2yfm-ValueContainer
  .css-1p3m7a8-multiValue
  .css-wsp0cs-MultiValueGeneric {
  padding: 0.375rem 0.375rem 0.375rem 0.75rem;
  color: var(--bs-primary);
}
.css-b62m3t-container
  .css-3w2yfm-ValueContainer
  .css-1p3m7a8-multiValue
  .css-12a83d4-MultiValueRemove {
  padding: 0.125rem;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.5rem;
  border: 1px solid;
  color: var(--bs-primary);
}
.css-b62m3t-container
  .css-3w2yfm-ValueContainer
  .css-1p3m7a8-multiValue
  .css-12a83d4-MultiValueRemove:hover {
  color: var(--bs-danger);
}

.MuiButtonBase-root.MuiButton-root {
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  font-family: var(--bs-body-font-family);
  font-weight: 600;
  line-height: 1.625rem;
  min-width: 2rem;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-outlined {
  padding: calc(0.5rem - 1px) 1.5rem;
}
.MuiButtonBase-root.MuiButton-root.MuiButton-icon {
  padding: 0.75rem;
}
.MuiButtonBase-root.MuiButton-containedPrimary {
  background-color: var(--bs-primary);
  color: #fff;
  box-shadow: none;
}
.MuiButtonBase-root.MuiButton-containedPrimary:hover,
.MuiButtonBase-root.MuiButton-containedPrimary:active {
  background-color: var(--bs-primary-hover);
  color: #fff;
  box-shadow: none;
}
.MuiButtonBase-root.MuiButton-containedPrimary.Mui-disabled {
  background-color: var(--bs-primary-hover);
  color: #fff;
  opacity: 0.75;
}

.MuiButtonBase-root.MuiButton-outlinedPrimary {
  background-color: #fff;
  border-color: var(--bs-primary);
  color: var(--bs-primary);
}
.MuiButtonBase-root.MuiButton-outlinedPrimary:hover {
  border-color: var(--bs-primary);
  background-color: rgba(var(--bs-primary-rgb), 0.06);
}

.MuiButtonBase-root.MuiButton-containedSecondary {
  background-color: var(--bs-primary);
  color: #fff;
  box-shadow: none;
}
.MuiButtonBase-root.MuiButton-containedSecondary:hover,
.MuiButtonBase-root.MuiButton-containedSecondary:active {
  background-color: var(--bs-secondary-hover);
  color: #fff;
  box-shadow: none;
}
.MuiButtonBase-root.MuiButton-containedSecondary.Mui-disabled {
  background-color: var(--bs-secondary-hover);
  color: #fff;
  opacity: 0.75;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
  padding: 0.375rem 0.5rem;
  font-family: var(--bs-body-font-family);
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 400;
  background-color: #1a1a1a;
}
.MuiTooltip-tooltip.MuiTooltip-tooltipPlacementTop {
  margin: 0.5rem 0;
}
.MuiTooltip-tooltip .MuiTooltip-arrow {
  color: #1a1a1a;
}

.ql-toolbar.ql-snow {
  border-color: var(--bs-border-color);
  background-color: #fcfcfc;
  font-family: var(--bs-body-font-family);
  border-radius: 0.25rem 0.25rem 0 0;
}
.ql-container.ql-snow {
  border-color: var(--bs-border-color);
  background-color: #fcfcfc;
  font-family: var(--bs-body-font-family);
  font-size: 1rem;
  border-radius: 0.25rem;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-radius: 0 0 0.25rem 0.25rem;
}

/* Input Icon */
.input-icon {
  position: relative;
}
.input-icon.icon-s .form-control,
.input-icon.icon-se .form-control {
  padding-left: 3rem;
}
.input-icon.icon-e .form-control,
.input-icon.icon-se .form-control {
  padding-right: 3rem;
}
.input-icon .icon {
  color: var(--bs-gray);
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  text-decoration: none;
}
.input-icon .icon svg {
  vertical-align: top;
}
.input-icon .icon-start {
  left: 0;
  padding: 0.5rem;
}
.input-icon .icon-end {
  right: 0;
}

.card-login
  .card
  .input-icon.icon-s
  .email-mui.MuiFormControl-root
  .MuiInputBase-root
  .MuiInputBase-input,
.card-login
  .card.input-icon.icon-s
  .email-mui.MuiFormControl-root
  .MuiInputBase-root
  .MuiInputBase-input {
  border: none;
  background-color: transparent;
  font-weight: 800;
  padding-left: 0;
  margin-left: 3.5rem;
}
.card-login
  .card
  .input-icon.icon-se
  .password-mui.MuiFormControl-root
  .MuiInputBase-root
  .MuiInputBase-input {
  border: none;
  background-color: transparent;
  font-weight: 800;
  padding-left: 0;
  margin-left: 3.5rem;
}

/* courses card */
.course-classname-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.course-classname-heading {
  align-self: flex-start;
  margin-bottom: 20px;
}

.course-classname-card-container {
  padding: 0 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.course-classname-card {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  box-sizing: border-box;
  background-color: #fff;
  padding: 1rem;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.15);
}

@media (min-width: 772px) {
  .course-classname-card {
    width: 50%;
  }
}

/* Checkbox */

.form-check-input[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 96 960 960'%3e%3cpath fill='%23000000' d='M480 976q-83 0-156-31.5T197 859q-54-54-85.5-127T80 576q0-83 31.5-156T197 293q54-54 127-85.5T480 176q83 0 156 31.5T763 293q54 54 85.5 127T880 576q0 83-31.5 156T763 859q-54 54-127 85.5T480 976Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 96 960 960'%3e%3cpath fill='%23000000' d='M480 776q83 0 141.5-58.5T680 576q0-83-58.5-141.5T480 376q-83 0-141.5 58.5T280 576q0 83 58.5 141.5T480 776Zm0 200q-83 0-156-31.5T197 859q-54-54-85.5-127T80 576q0-83 31.5-156T197 293q54-54 127-85.5T480 176q83 0 156 31.5T763 293q54 54 85.5 127T880 576q0 83-31.5 156T763 859q-54 54-127 85.5T480 976Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 1.25rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23CECECE' d='M2.66667 24C1.93333 24 1.30533 23.7391 0.782667 23.2173C0.260889 22.6947 0 22.0667 0 21.3333V2.66667C0 1.93333 0.260889 1.30533 0.782667 0.782667C1.30533 0.260889 1.93333 0 2.66667 0H21.3333C22.0667 0 22.6947 0.260889 23.2173 0.782667C23.7391 1.30533 24 1.93333 24 2.66667V21.3333C24 22.0667 23.7391 22.6947 23.2173 23.2173C22.6947 23.7391 22.0667 24 21.3333 24H2.66667ZM2.66667 21.3333H21.3333V2.66667H2.66667V21.3333Z'/%3e%3c/svg%3e");
}

.checkbox input:checked ~ .checkmark {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%236358DC' d='M2.66667 24C1.93333 24 1.30533 23.7391 0.782667 23.2173C0.260889 22.6947 0 22.0667 0 21.3333V2.66667C0 1.93333 0.260889 1.30533 0.782667 0.782667C1.30533 0.260889 1.93333 0 2.66667 0H21.3333C22.0667 0 22.6947 0.260889 23.2173 0.782667C23.7391 1.30533 24 1.93333 24 2.66667V21.3333C24 22.0667 23.7391 22.6947 23.2173 23.2173C22.6947 23.7391 22.0667 24 21.3333 24H2.66667ZM2.66667 21.3333H21.3333V2.66667H2.66667V21.3333ZM10.1333 17.0333C10.3111 17.0333 10.4778 17.0058 10.6333 16.9507C10.7889 16.8947 10.9333 16.8 11.0667 16.6667L18.6333 9.1C18.8778 8.85556 19 8.55556 19 8.2C19 7.84444 18.8667 7.53333 18.6 7.26667C18.3556 7.02222 18.0444 6.9 17.6667 6.9C17.2889 6.9 16.9778 7.02222 16.7333 7.26667L10.1333 13.8667L7.23333 10.9667C6.98889 10.7222 6.68889 10.6 6.33333 10.6C5.97778 10.6 5.66667 10.7333 5.4 11C5.15556 11.2444 5.03333 11.5556 5.03333 11.9333C5.03333 12.3111 5.15556 12.6222 5.4 12.8667L9.2 16.6667C9.33333 16.8 9.47778 16.8947 9.63333 16.9507C9.78889 17.0058 9.95556 17.0333 10.1333 17.0333Z'/%3e%3c/svg%3e");
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Loader */
.loader {
  background-color: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 8;
}
.loader .loader-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.loader .loader-body .MuiCircularProgress-root {
  width: 2.5rem;
  height: 2.5rem;
}
.loader .loader-body .loader-text {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 500;
  width: 200px;
  margin-top: 0.5rem;
}

.loader-fill {
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0, #6358dc 100%);
  background-attachment: fixed;
  color: #fff;
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 8;
}
.loader-fill .loader-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 100%;
  padding: 0 2rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.loader-fill .loader-body .MuiCircularProgress-root {
  width: 2.5rem;
  height: 2.5rem;
}
.loader-fill .loader-body .loader-text {
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 700;
  margin-top: 3.5rem;
  background: linear-gradient(90.07deg, #6a41fb 10.35%, #f49959 92.29%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.loader-fill .loader-body .loader-text img {
  width: 2rem;
}

/* loader bar */
.loader-bar-line {
  position: absolute;
  top: 2px;
  left: 0;
  width: 100%;
  height: 4px;
  background: #ffffff;
  z-index: 1;
  opacity: 0.9;
  border-radius: 5px;
}
.loader-bar {
  position: relative;
  width: 1077px;
  max-width: 100%;
  height: 8px;
  -webkit-box-reflect: below above 2px linear-gradient(transparent, #0005);
  margin-top: 30px;
}
.loader-bar::before {
  content: "";
  position: absolute;
  inset: 0;
  filter: blur(5px);
  background: linear-gradient(
    90deg,
    #fff,
    #0000ff,
    #ff0000,
    #fff,
    #0000ff,
    #00ff00,
    #fff,
    #ff0000,
    #00ff00,
    #fff,
    #ff0000,
    #0000ff,
    #fff
  );
  animation: animate 14s linear infinite;
  background-size: 400%;
}
.loader-bar::after {
  content: "";
  position: absolute;
  inset: 0;
  filter: blur(5px);
  background: linear-gradient(
    90deg,
    #fff,
    #0000ff,
    #ff0000,
    #fff,
    #0000ff,
    #00ff00,
    #fff,
    #ff0000,
    #00ff00,
    #fff,
    #ff0000,
    #0000ff,
    #fff
  );
  animation: animate 14s linear infinite;
  background-size: 400%;
}

/* Dialog */
.MuiDialog-root .MuiDialogTitle-root {
  padding: 1rem 3rem 1rem 1.25rem;
  position: relative;
}
.MuiDialog-root .MuiDialogTitle-root .MuiTypography-root {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 600;
  font-family: var(--bs-body-font-family);
}
.MuiDialog-root .MuiDialogTitle-root .MuiIconButton-root {
  position: absolute;
  right: 0.75rem;
  top: 0.75rem;
}
.MuiDialog-root .MuiDialogContent-dividers {
  padding: 1.5rem;
}
.MuiDialog-root .MuiDialogContent-no-padding {
  padding: 0 !important;
}
.MuiDialog-root .MuiDialogActions-root {
  padding: 1.25rem 1.5rem;
}
.MuiDialog-root .MuiDialogActions-spacing > :not(:first-child) {
  margin-left: 1rem;
}
.MuiPaper-root.MuiPaper-rounded {
  border-radius: 0rem;
}

/* Icons */
.MuiSvgIcon-root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  -moz-user-select: none;
}
.icon-size {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
}
.icon-24 {
  font-size: 1.5rem;
}

.video-wrap {
  position: relative;
  padding-bottom: 56.66666%;
  height: 0;
  border-radius: 0.375rem;
  overflow: hidden;
}
.video-wrap > iframe {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.fs-7 {
  font-size: 0.5625rem;
  margin-right: 1.75rem;
}
.fs-9 {
  font-size: 0.5625rem;
}
.fs-14 {
  font-size: 0.875rem;
}
.mt-n2 {
  margin-top: -1rem;
}
.mt-n1 {
  margin-top: -0.375rem;
}
.mt-small {
  margin-top: 0.25rem;
}
.popover-container {
  z-index: 1;
  position: relative;
  display: inline;
}

/* Card */
.card {
  --bs-card-spacer-y: 1.5rem;
  --bs-card-spacer-x: 1.5rem;
  --bs-card-title-spacer-y: 0;
  --bs-card-border-width: 0;
  --bs-card-border-color: transparent;
  --bs-card-border-radius: 0.375rem;
  --bs-card-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.15);
  --bs-card-inner-border-radius: 0.375rem;
  --bs-card-cap-padding-y: 1rem;
  --bs-card-cap-padding-x: 1.5rem;
  --bs-card-cap-bg: #f9f9f9;
  --bs-card-cap-color: var(--bs-body-color);
  --bs-card-bg: #fff;
  --bs-card-footer-bg: #fff;
  --bs-card-img-overlay-padding: 1rem;
  box-shadow: var(--bs-card-box-shadow);
  width: 100%;
}

.card-light {
  border-radius: 44px;
  background: rgba(242, 242, 245, 0.6);
  backdrop-filter: blur(39px);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 4rem 2.5rem;
}
.card-bg-image {
  background-image: url("../assets/image/home-bg-1.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.card-login {
  --bs-card-spacer-y: 2.5rem;
  --bs-card-spacer-x: 3rem;
  width: 650px;
  max-width: 100%;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  border: 1px solid #ffffff;
}
.card-grd {
  --bs-card-spacer-y: 2.25rem;
  --bs-card-spacer-x: 3rem;
  /* --bs-card-border-width: 1px; */
  /* --bs-card-border-color: rgba(255, 255, 255, 0.8); */
  --bs-card-border-radius: 1.5rem;
  /* --bs-card-inner-border-radius: 1rem; */
  /* --bs-card-box-shadow: none; */
  margin-top: 1.5rem;
  background: linear-gradient(211.59deg, #f9fafc 2.48%, #0e4fb9 790.99%);
  border: 2px solid;
  border-image-source: linear-gradient(
    180deg,
    #dddddd 0%,
    rgba(102, 102, 102, 0.48) 100%
  );
  box-shadow: 0px 6px 14px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 26px 26px 0px rgba(0, 0, 0, 0.09);
  box-shadow: 0px 58px 35px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 103px 41px 0px rgba(0, 0, 0, 0.01);
  box-shadow: 0px 161px 45px 0px rgba(0, 0, 0, 0);
}
.card-grd.md {
  --bs-card-spacer-y: 1.75rem;
  --bs-card-spacer-x: 2rem;
}
.card-db-items {
  --bs-card-spacer-y: 2.5rem;
  --bs-card-spacer-x: 2.25rem;
  --bs-card-border-width: 2px;
}
.card-draft {
  --bs-card-spacer-y: 1.25rem;
  --bs-card-spacer-x: 2.5rem;
}
.card-bordered {
  --bs-card-spacer-y: 0.5rem;
  --bs-card-spacer-x: 0.5rem;
  border-bottom: 3px solid var(--bs-primary);
}
.card-home {
  --bs-card-spacer-y: 1.5rem;
  --bs-card-spacer-x: 1.25rem;
  --bs-card-border-radius: 0.625rem;
  --bs-card-box-shadow: 0 0.13rem 1.25rem rgba(0, 0, 0, 0.1);
  --bs-card-inner-border-radius: 1.25rem;
}
.card-apc {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-border-radius: 1.25rem;
  --bs-card-box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
  --bs-card-inner-border-radius: 1.25rem;
}
.card-textarea {
  --bs-card-spacer-y: 1.125rem;
  --bs-card-spacer-x: 1.125rem;
  --bs-card-border-radius: 0.5rem;
  --bs-card-inner-border-radius: 0.5rem;
}
.card-title {
  text-transform: uppercase;
}
.card-body {
  padding-bottom: 0.5rem;
}
.card-bordered .card-body {
  padding-bottom: 0;
}
.card-login .card-body {
  padding-bottom: var(--bs-card-spacer-y);
}
.card-footer {
  padding: var(--bs-card-cap-padding-x);
  background-color: var(--bs-card-footer-bg);
  border-top: 1px solid var(--bs-border-color);
}
.items-buttons {
  margin-top: -1rem;
  display: flex;
  flex-wrap: wrap;
}
.items-buttons .MuiButton-root {
  margin-top: 1rem;
}
.items-buttons .MuiButton-root + .MuiButton-root {
  margin-left: 1rem;
}
.card-header:not(.card-active) {
  border-bottom-left-radius: var(--bs-card-border-radius);
  border-bottom-right-radius: var(--bs-card-border-radius);
}

.card-bordered .items-cb-head {
  padding: 1rem 1rem 0;
  font-weight: 600;
  color: #2d2d2d;
  background-color: #f9f9f9;
  border-radius: 0.25rem;
}
.card-bordered .items-cb-head .item-name {
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5rem;
}
.card-bordered .items-cb-head .item-text-gray {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #828282;
}
.card-bordered .items-cb-head .MuiIconButton-root {
  margin: -0.75rem -0.5rem 0 0;
  padding: 0.375rem;
}
.card-bordered .items-cb-body {
  padding: 1rem 1rem 0;
  font-weight: 600;
  color: #2d2d2d;
}
.card-bordered .items-cb-body .item-text {
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-bottom: 1rem;
}
.card-bordered .items-cb-body .item-text .item-label {
  color: #828282;
  display: block;
}

/*.card-db-items:hover{ border-color: var(--bs-primary);}*/
.card-db-items {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-db-items .card-body {
  padding-bottom: var(--bs-card-spacer-y);
  width: 100%;
}
.card-db-items .item-wrap {
  position: relative;
  padding-left: 4.25rem;
  font-size: 1rem;
  font-weight: 500;
}
.card-db-items .item-wrap .item-icon {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--bs-primary-rgb), 0.1);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
}
.card-db-items .item-wrap .item-name {
  color: #2d2d2d;
  margin-bottom: 0.25rem;
}
.card-db-items .item-wrap .item-text {
  color: #a1a1a1;
}
.card-db-items .item-image {
  margin-bottom: 1.25rem;
}
.toggle-arrow {
  width: 1em;
  height: 1em;
  font-size: 1.25rem;
}

/* Interview Buddy */
.items-interview-info {
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  background-color: var(--bs-light-blue);
  border-radius: 0.375rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.15);
}
.items-interview-info .item-text {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.items-qa .item-text {
  padding: 1rem 1.5rem;
  background-color: var(--bs-white);
  border-radius: 0.375rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.15);
}
.items-qa .MuiButtonBase-root.MuiButton-root {
  border-radius: 0.375rem;
}

/* Login */
.card-login h1 span {
  font-size: 1.9rem;
  line-height: 2.5rem;
  font-weight: 900;
  color: #6358dc;
}
.card-login .login-title {
  font-size: 2.25rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: 2rem;
}
.text-or {
  position: relative;
  height: 1px;
  background-color: var(--bs-border-color);
  margin: 0.5rem 0;
  display: inline-block;
  width: 100%;
}
.text-or span {
  padding: 0 1.5rem;
  background-color: #ebedf8;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* Interview Dahboard */
.page-interview-dashboard {
  padding: 5rem 0;
  min-height: 6.25rem;
}
.page-interview-dashboard .pid-items-wrap {
  padding: 1.5rem;
  background-color: #fff;
  border-radius: 0.75rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.15);
}
.page-interview-dashboard .pid-items-wrap > .row {
  min-height: calc(100vh - 288px);
}
.pid-items {
  padding: 1.5 1.25rem;
  min-height: 220px;
  background-color: var(--bs-primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.75rem;
}
.pid-items .item-icon {
  margin-bottom: 1.125rem;
}
.pid-items .item-name {
  font-size: 1.5rem;
  line-height: 1.875rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
}

.card-draft .item-name {
  font-size: 2rem;
  line-height: 1;
  font-weight: 700;
  color: #000000;
  margin-bottom: 0.75rem;
  padding: 0 0.5rem;
}
.card-draft .item-deg {
  font-size: 1.5rem;
  line-height: 1;
  font-weight: 400;
  color: #000000;
  padding: 0.5rem 1rem 0.2rem 0.5rem;
}
.card-draft .item-info {
  padding: 0 0.75rem;
}
.card-draft .item-title {
  font-size: 1.25rem;
  line-height: 1.4;
  font-weight: 400;
  color: #000000;
  margin-bottom: 0.5rem;
  padding: 0.125rem 0.25rem;
  border-bottom: 2px solid var(--bs-border-color);
}
.card-draft .item-body {
  padding-bottom: 1.5rem;
}
.card-draft .item-body .item-text {
  padding: 0rem 0.25rem;
}
.card-draft .item-header {
  padding: 0.75rem 0 0 0;
  margin-bottom: 1rem;
  border-bottom: 3px solid var(--bs-border-color);
}
.card-draft .item-header .item-info-row {
  margin: 0 -1.5rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.card-draft .item-header .item-info-row .icon-left {
  padding: 0 1rem;
  margin: 0.25rem 0;
  display: inline-flex;
  align-items: center;
}
.card-draft .item-header .item-info-row .icon-left:not(:last-child) {
  border-right: 2px solid var(--bs-border-color);
}
.card-draft .item-header .item-info-row .icon-left .icon {
  margin-right: 0.5rem;
}
.items-data .item-repeats {
  padding: 0 1rem;
  margin: var(--eleEditMargin);
  position: relative;
}
.items-data .item-repeats + .item-repeats {
  margin-top: 0.75rem;
}
.items-data .item-repeats + .item-repeats:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: -0.75rem;
}
.items-data .item-repeats ul {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
}
.items-data .item-repeats ul li,
.items-data .item-list {
  position: relative;
  padding-left: 1rem;
}
.items-data .item-repeats ul li:before,
.items-data .item-list:before {
  content: "";
  position: absolute;
  left: 0;
  top: 7px;
  width: 0.375rem;
  height: 0.375rem;
  background-color: #2d2d2d;
  border-radius: 50%;
}
.items-data .item-desc {
  margin-top: 0.5rem;
  padding: 0 1rem;
}
.items-data .item-repeats .h6 {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 700;
  color: #000000;
}

.ele-edit-wrap {
  position: relative;
  margin: var(--eleEditMargin);
}
.ele-edit-wrap:hover:before,
.ele-edit-pre:hover .ele-edit-wrap:before {
  content: "";
  position: absolute;
  left: 0;
  top: -0.25rem;
  right: 0;
  bottom: -0.25rem;
  border: 1px dashed #aaa;
  pointer-events: none;
}
.ele-edit-wrap > .ele-edit {
  visibility: hidden;
  position: absolute;
  left: -2rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  background: var(--bs-border-color);
  padding: 0.25rem;
  border-radius: 0.125rem;
  /* transition: visibility 0s ease 0.3s; */
}

.ele-edit-wrap:hover > .ele-edit {
  visibility: visible;
  transition: visibility 0s ease 0s; /* Instantly make visible */
  animation: slideOut 0.5s ease-out forwards; /* Apply slide-in animation */
}

@keyframes slideOut {
  0% {
    transform: translateY(-50%) translateX(50%);
    opacity: 0;
  }
  100% {
    transform: translateY(-50%) translateX(0%);
    opacity: 1;
  }
}

.card-draft-actions {
  margin-top: 1.5rem;
}

.upload-image img {
  object-fit: cover;
  border-radius: 50%;
}

/* Edit Resume Accordion */
.accordion {
  --bs-accordion-color: #2d2d2d;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.75rem;
  --bs-accordion-inner-border-radius: calc(0.75rem - 1px);
  --bs-accordion-btn-padding-x: 1.5rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #2d2d2d;
  --bs-accordion-btn-bg: #f9f9f9;
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27%232D2D2D%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M12 15.3746L6 9.37461L7.4 7.97461L12 12.5746L16.6 7.97461L18 9.37461L12 15.3746Z%27/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.5rem;
  --bs-accordion-btn-icon-transform: rotate(180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 24 24%27 fill=%27%232D2D2D%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M12 15.3746L6 9.37461L7.4 7.97461L12 12.5746L16.6 7.97461L18 9.37461L12 15.3746Z%27/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: none;
  --bs-accordion-body-padding-x: 1.5rem;
  --bs-accordion-body-padding-y: 1.5rem;
  --bs-accordion-active-color: #2d2d2d;
  --bs-accordion-active-bg: #f9f9f9;
}

.accordion-resume {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.25);
  border-radius: var(--bs-accordion-border-radius);
}
.accordion-resume .accordion-header {
  display: flex;
  justify-content: space-between;
  background-color: var(--bs-accordion-btn-bg);
}
.accordion-resume .accordion-item:first-of-type .accordion-header {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-resume .accordion-button {
  padding-left: 3rem;
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: bold;
}
.accordion-resume .accordion-button:after {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%) rotate(0deg);
}
.accordion-resume .accordion-button:not(.collapsed):after {
  transform: translateY(-50%) var(--bs-accordion-btn-icon-transform);
}
.accordion-resume .accordion-header .add-btn {
  padding: 0.5rem 1.25rem;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--bs-primary);
  background-color: var(--bs-accordion-btn-bg);
  border: none;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.accordion-resume .accordion-header .accordion-button:not(.collapsed):focus,
.accordion-resume
  .accordion-header
  .accordion-button:not(.collapsed)
  + .add-btn {
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
    var(--bs-accordion-border-color);
}
.accordion-resume .accordion-item:first-of-type .accordion-header .add-btn {
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-resume .accordion-item .accordion-header .add-btn .icon {
  font-size: 1.375rem;
}
.accordion-resume .accordion-body {
  padding-bottom: 0.5rem;
}
.items-repeat + .items-repeat {
  margin-top: 0.75rem;
  padding-top: 1.5rem;
  border-top: 1px solid var(--bs-border-color);
}
.items-repeat .item-head {
  display: flex;
  justify-content: space-between;
}
.items-repeat .item-head h3 {
  font-size: 1.125rem;
}

.items-template {
  width: 100%;
  border: 2px solid transparent;
  display: inline-block;
  vertical-align: top;
  border-radius: calc(0.25rem + 1px);
}
.items-template.active {
  border-color: var(--bs-primary);
}
.items-template .item-image {
  border: 1.5rem solid #eff2f9;
  border-radius: 0.25rem;
}

.h-33 {
  height: 650px;
}

.tag {
  padding: 0.375rem 1.5rem;
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-weight: 500;
  border-radius: 0.25rem;
  display: inline-block;
  vertical-align: top;
}
.tag-outline {
  border: 1px solid var(--bs-primary);
  background-color: #fff;
}

.items-sync-profile {
  position: relative;
  padding-left: 11.875rem;
  min-height: 6rem;
}
.items-sync-profile .item-image {
  position: absolute;
  left: 0;
  top: 0;
  transform: translateY(-40%);
}
.items-sync-profile .item-image img {
  width: 9.625rem;
  height: 9.625rem;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.items-sync-profile .item-link {
  display: inline-block;
  text-align: center;
  font-weight: bold;
}
.items-sync-profile .item-link .icon {
  font-size: 2rem;
}

.sp-icon-left {
  padding-left: 4rem;
  min-height: 3rem;
  line-height: 1.375rem;
  text-align: left;
  position: relative;
  display: inline-flex;
  flex-flow: column;
  justify-content: center;
  font-weight: bold;
}
.sp-icon-left .item-icon {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(18, 150, 224, 0.1);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
}
.sp-icon-left .item-icon .icon {
  font-size: 2rem;
  color: var(--bs-primary);
}
.sp-icon-left .item-text {
  color: #a1a1a1;
}

/* home page */
.page-home {
  padding: 0;
}
.home-footer {
  background: #07223f;
  color: #dedede;
}
.footer-link-text {
  cursor: pointer;
  color: #dedede;
}

.section {
  padding: 6.25rem 0 0;
}
.section.section-our-clients {
  padding-top: 0;
}
.section-gif-block .gif-content .gif-title {
  background-color: var(--bs-secondary);
  color: white;
  padding: 1.25rem 1.5rem;
  border-radius: var(--bs-border-radius-pill);
  text-align: center;
  text-transform: none;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  display: inline-block;
}
.section-gif-block .gif-content .gif-image-wrap {
  padding: 1.125rem;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  border-radius: 1.25rem;
  box-shadow: 0 0.125rem 1.25rem 0 rgba(0, 0, 0, 0.1);
}
.section-gif-block .gif-content .gif-image {
  border-radius: 0.75rem;
}
.section-gif-block .gif-content .curly-arrow {
  margin-left: -3rem;
  margin-top: -1.5rem;
}
.items-we-provide {
  padding: 2.5rem 3rem 1.375rem;
  text-align: center;
  width: 272px;
  max-width: 100%;
  height: 100%;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: var(
    --gradient,
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0.1) 100%
    )
  );
  border-radius: 1.125rem;
  box-shadow: 0 0.75rem 1.25rem 0 rgba(99, 88, 220, 0.1);
}
.items-we-provide .item-icon {
  margin-bottom: 1.5rem;
}
.items-we-provide .item-name {
  font-size: 1.25rem;
  font-weight: 500;
}

.page-home.bottom-bg {
  background: url(/src/assets/image/home-img.png);
  background-size: 35% auto;
  background-position: right bottom;
  background-repeat: no-repeat;
  z-index: 1;
  padding-bottom: 31vw;
}

.page-home .section-heading {
  padding-bottom: 2rem;
  text-align: center;
  color: var(--bs-secondary);
}
.page-home .section-heading .item-tag {
  font-size: 1.25rem;
  line-height: 1.25;
}
.page-home .section-heading .item-title {
  font-size: 1.75rem;
  line-height: 0.75;
  font-weight: 700;
  text-align: start;
  margin-top: 2rem;
}
.page-home .section-heading .item-sub-text {
  font-size: 1.25rem;
  line-height: 1.25;
  font-weight: 600;
}
.page-home .section-heading .item-text {
  font-size: 1.25rem;
  line-height: 1.5;
}
.page-home .section-heading .item-btn {
  margin-top: 1.5rem;
}
.page-home .section-heading .item-avatar {
  margin-bottom: 0.5rem;
}
.page-home .section-heading .item-avatar img {
  width: 11.875rem;
  height: 11.875rem;
  background-color: #fff;
  border: 3px solid var(--bs-purple);
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.card-home {
  text-align: center;
}
.card-home.step-by-step {
  background: linear-gradient(
    180deg,
    rgba(124, 132, 212, 0.4) 0%,
    rgba(124, 132, 212, 0.1) 100%
  );
  color: #7c84d4;
  border: 1px solid #7c84d4;
}
.card-home.existing-resume {
  background: linear-gradient(
    180deg,
    rgba(0, 153, 255, 0.4) 0%,
    rgba(0, 153, 255, 0.1) 100%
  );
  border: 1px solid #1296e0;
  color: #1296e0;
}
.card-home.linked-in {
  background: linear-gradient(
    180deg,
    rgba(0, 122, 182, 0.4) 0%,
    rgba(0, 122, 182, 0.1) 100%
  );
  border: 1px solid #9cd4ee;
  color: #007ab6;
}
.card-home.free-template {
  background: linear-gradient(
    180deg,
    rgba(222, 222, 222, 0.4) 0%,
    rgba(222, 222, 222, 0.1) 100%
  );
  border: 1px solid rgba(124, 132, 212, 0.5);
}
.card-home .item-icon {
  margin-bottom: 1rem;
}
.card-home .item-icon img {
  width: 7.5rem;
  max-width: 100%;
}
.card-home .item-title {
  font-size: 1.5rem;
  line-height: 1.25;
  font-weight: 600;
  color: var(--bs-purple);
  margin-bottom: 1rem;
}
.card-home .card-description {
  font-size: 0.75rem;
  color: #000000;
  font-weight: 400;
}

.card.card-upload {
  background: rgba(255, 255, 255, 0.8);
  border: 1px dashed #7c84d4;
  border-radius: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0rem 2rem 0rem;
}
.card.card-upload .card-body .card-text {
  font-size: 1.5rem;
  font-weight: 400;
  align-items: center;
  display: flex;
  justify-content: center;
}

.page-templates {
  padding: 2rem 0;
}
.page-onboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-apc .card-image-wrap {
  position: relative;
  height: 0;
  padding-bottom: 110%;
}
.card-apc .card-image-wrap .card-img-top {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 1.25rem;
  cursor: pointer;
  object-fit: cover;
  object-position: center top;
}
.card-apc .card-image-wrap .card-img-top:hover {
  opacity: 0.7;
}
.card-apc .card-image-wrap .item-title {
  text-align: center;
  color: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 1.25rem 1rem;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  width: 100%;
  border-radius: 0 0 1.25rem 1.25rem;
}
.card-apc .card-desc {
  background-color: rgb(99, 88, 220, 0.1);
  border-radius: 1.25rem;
  font-size: 0.75rem;
  line-height: 1rem;
}

.experience-content .experience-title {
  color: #000000;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}
.experience-content .experience-company-title {
  color: #6358dc;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.empty-experience-content {
  padding: 0 1rem;
}
.empty-experience-content .experience-title {
  color: #000000;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}
.empty-experience-content .experience-text {
  color: #000000;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.video-fixed-wrap {
  position: relative;
}
.video-fixed-wrap .video-button {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 0.125rem 0.5rem 0.125rem 0.125rem;
  border-radius: var(--bs-border-radius-pill);
}
.video-modal2 {
  bottom: 1rem;
  left: 1rem;
  width: 100%;
  max-width: 100%;
  background-color: var(--bs-light);
}
.video-fixed-wrap .video-modal {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  width: 50%;
  max-width: 100%;
  background-color: var(--bs-light);
}
.video-fixed-wrap .video-modal .btn-close {
  position: absolute;
  right: 0.375rem;
  top: 0.375rem;
  background-color: var(--bs-light);
  padding: 0.5rem;
  font-size: 0.75rem;
  opacity: 1;
  border-radius: 50%;
  z-index: 1;
}
.video-fixed-wrap .video-modal .video-wrap {
  width: 100%;
}
.summary-video {
  margin-top: 1.125rem;
  position: relative;
}
.float-video {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  z-index: 1;
  /*margin: 0 auto 0.9375rem auto;
  padding-right: 1.25rem;*/
}
.summary-video .video-button {
  font-size: 1.2rem;
  font-weight: 700;
  padding: 0.125rem 0.5rem 0.125rem 0.125rem;
  border-radius: var(--bs-border-radius-pill);
  cursor: pointer;
  /*position: absolute;
  bottom: 0%;
  left: 1%;*/
}
.container .summary-video-text {
  color: #6358dc;
  font-size: 1.5rem;
  text-align: center;
}
.container .summary-title {
  color: #6358dc;
  font-size: 1.75rem;
  text-align: center;
}
.container .summary-question-title {
  color: #000000;
  font-size: 2.5rem;
  text-align: center;
}
.container .summary-question-heading {
  color: #6358dc;
  font-size: 1.5rem;
}
.summary-preview-title {
  color: #6358dc;
  font-size: 1.5rem;
}
.container .education-text {
  color: #000000;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.experience-question-title {
  color: #000000;
  font-size: 1.25rem;
  margin-bottom: 1rem;
  font-weight: 500;
}

.summary-question-title {
  color: #000000;
  font-size: 2.5rem;
  text-align: center;
}

.skill-content {
  padding: 10% 5%;
}
.skill-content .skill-title {
  color: #000000;
  font-size: 2.5rem;
  font-weight: 500;
}
.skill-content .skill-text {
  color: #6358dc;
  font-size: 1.5rem;
  line-height: 2rem;
  padding: 1rem 0;
  font-weight: 500;
}
.skill-content .card {
  padding: 1rem 2rem 3rem 2rem;
  width: 100%;
  border-radius: 0.5rem;
  box-shadow: none;
  margin-top: 2rem;
}

.card.create-summary {
  margin-top: 1.5rem;
  padding: 0.75rem 1.5rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  border: 1px solid #ffffff;
  border-radius: 6.25rem;
}
.card.create-summary .card-text {
  flex: 1 0 0;
}
.card.create-summary .form-check-only {
  flex: 0 0 auto;
}
.card.summary-questions {
  padding: 0.75rem 3rem 0.75rem 1.125rem;
  background: #07223f;
  color: #ffffff;
}
.card .card-text .downArrow {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.card-textarea .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
  background-color: transparent;
  border-width: 0;
  padding: 0;
  margin-bottom: 1rem;
}
.card-textarea .ct-info-text {
  padding: 1rem;
  margin-bottom: 0.5rem;
  background-color: #f3f3f3;
  font-size: 0.75rem;
  line-height: 1rem;
  text-align: center;
  border-radius: 0.25rem;
}
.card.card-textarea.customize-summary {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  border: 1px solid #7c84d4;
  box-shadow: none;
}

.summary-tab {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-right: 1px solid #07223f;
  width: 12.5rem;
}
.project-tab {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-right: 1px solid #07223f;
}
.summary-tab > div {
  height: 3.375rem;
  border-radius: 0.5rem 0 0 0.5rem;
  text-align: center;
  padding-top: 0.9375rem;
}
.summary-tab > div.active {
  border-right: 3px solid black;
  border-color: #07223f;
  background-color: rgba(7, 34, 63, 0.5);
  color: #ffffff;
}
.summary-preview-body .summary-preview-bg {
  padding: 0.25rem 0.25rem;
  background-color: #000;
  /* border: white 1px solid; */
  border-radius: 0.25rem;
  /*margin-bottom: 1rem;*/
}
.summary-preview-body .summary-preview-bg > .card {
  min-height: calc(100vh - 208px);
}

.hr-line {
  margin: 3.125rem auto;
  color: #707070;
  text-align: center;
  font-size: 1rem;
  max-width: 600px;
  position: relative;
}
.hr-line:before {
  content: "";
  display: block;
  width: 292px;
  height: 2px;
  background: #bfbfbf;
  position: absolute;
  left: 160%;
  top: 50%;
  z-index: -2;
}
.hr-line:after {
  content: "";
  display: block;
  width: 292px;
  height: 2px;
  position: absolute;
  background: #bfbfbf;
  right: 160%;
  top: 50%;
  z-index: -2;
}

.material-button {
  text-transform: none;
}

/* Create resume css  */
.create-resume-item-name-writer {
  font-size: 0.8125rem;
  line-height: 1.875rem;
  font-weight: 700;
  color: #2d2d2d;
  margin-bottom: 0.3rem;
  text-align: center;
}
.create-resume-item-info {
  font-size: 0.5rem;
}
.create-resume-icon-24 {
  font-size: 0.8125rem;
}

.card-draft .item-header-writer .item-info-row-writer .create-resume-icon-left {
  padding: 0 0.5rem;
  display: inline-flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.card-draft
  .item-header-writer
  .item-info-row-writer
  .create-resume-icon-left:not(:last-child) {
  border-right: 2px solid var(--bs-border-color);
}
.card-draft
  .item-header-writer
  .item-info-row-writer
  .create-resume-icon-left
  .icon {
  margin-right: 0.5rem;
}
.card-draft
  .item-header-writer
  .item-info-row-writer
  .create-resume-icon-left
  .material-symbols-outlined {
  font-size: 0.8125rem;
}
.card-draft .item-header-writer .create-resume-item-deg {
  font-size: 0.5625rem;
  line-height: 1rem;
  font-weight: 700;
  color: #2d2d2d;
  margin: 0.4rem 0;
  padding: 0.25rem 0;
  text-align: center;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}
.card-draft .create-resume-summary-body {
  padding-bottom: 0.4rem;
}
.create-resume-summary-body .create-resume-item-text {
  font-size: 0.5625rem;
  padding: 0rem 0.25rem;
}

.card-draft .create-resume-title-writer {
  font-size: 0.8rem;
  line-height: 1.75rem;
  font-weight: 500;
  color: #2d2d2d;
  margin-bottom: 0.125rem;
  padding-bottom: 0.4rem;
  text-align: center;
  text-decoration: underline;
}
.create-resume-list-writer {
  align-items: center;
  margin-bottom: 0.25rem;
  padding-right: 0.25rem;
  font-size: 0.5625rem;
}
.create-resume-list-writer::before {
  content: "•";
  margin-right: 0.25rem;
}
.item-skill-writer .create-resume-skill-data {
  flex-basis: max-content;
  display: flex;
  padding: 0%;
}
.create-resume-technical-data {
  display: flex;
  flex-direction: column;
}
.item-skill-writer .create-resume-skill-data:last-child .item-list {
  content: "•";
  margin-left: 1.25rem;
}
.create-resume-skill-data:last-child::after {
  content: "•";
  margin-left: 0.125rem;
  font-size: 0.5625rem;
}

.items-data .create-resume-repeats {
  padding: 0rem 1.25rem 0.5rem 1.25rem;
  margin: var(--eleEditMargin);
  position: relative;
  font-size: 0.5625rem;
}
.items-data .create-resume-repeats + .create-resume-repeats {
  margin-top: 0.5rem;
}
.items-data .create-resume-repeats + .create-resume-repeats:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: -0.75rem;
}
.items-data .create-resume-repeats ul {
  margin: -0.875rem 0 0.25rem 0;
  padding-left: 0;
  list-style: none;
}
.items-data .create-resume-repeats ul li,
.items-data .item-list {
  position: relative;
  padding-left: 1rem;
}
.items-data .create-resume-repeats ul li:before,
.items-data .item-list:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.25rem;
  width: 0.25rem;
  height: 0.25rem;
  background-color: #2d2d2d;
  border-radius: 50%;
}
.items-data .create-resume-desc {
  margin-top: 1rem;
  padding: 0 0.625rem;
}
.items-data .create-resume-repeats .h6 {
  font-size: 0.5625rem;
  line-height: 1.5;
  font-weight: 700;
  color: #000000;
}

.create-resume-data .item-repeats {
  display: flex;
  flex-direction: column;
}
.create-resume-data .item-repeats + .item-repeats {
  margin-top: 0.15rem;
}
.create-resume-info-certifications,
.create-resume-info-education {
  display: inline-flex;
  font-size: 0.5625rem;
}

/* cover letter css  */
.cover-letter-body {
  font-family: "Times New Roman", Times, serif;
  color: #000;
}
.cover-letter-body .cover-letter-preview-bg {
  padding: 1.125rem 1.5rem;
  background-color: #eff2f9;
  border-radius: 0.25rem;
}
.cover-letter-body .cover-letter-preview-bg > .card {
  min-height: calc(100vh - 208px);
}
.cover-letter-name {
  font-size: 1rem;
  font-weight: 700;
  color: #2d2d2d;
  margin-bottom: 0.3rem;
  margin-left: 0.5rem;
}
.card-draft .item-header-writer .cover-letter-item-deg {
  font-size: 0.875rem;
  font-weight: 400;
  color: #2d2d2d;
  margin: 0.4rem 0;
  padding: 0.25rem 0;
  border-bottom: 1px solid black;
  margin-left: 0.5rem;
}
.card-draft .item-header-writer .cover-letter-details {
  margin: 0.5rem 0 0 0.6875rem;
  font-size: 0.75rem;
  line-height: 0.8125rem;
}
.card-draft
  .item-header-writer
  .cover-letter-details
  .cover-letter-details-text {
  margin-top: 0.13rem;
  font-weight: 400;
}
.card-draft .cover-letter-description {
  border-top: 3px solid black;
}
.card-draft .cover-letter-description .cover-letter-description-text {
  margin: 2rem 0 0 0.6875rem;
  font-size: 0.75rem;
  line-height: 0.8125rem;
}

/* career claw  */
.submit-info-header {
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: var(--bs-body-bg);
}
.submit-info-header .navbar {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  border-bottom: 1px solid #ffffff;
}
.submit-info-tab {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  margin-bottom: 1rem;
  border-bottom: 1px solid #ffffff;
  border-radius: 0.625rem;
}
.nav-pills {
  --bs-nav-link-color: var(--bs-secondary);
  --bs-nav-pills-link-bg: rgba(var(--bs-secondary-rgb), 0.1);
  --bs-nav-link-hover-color: var(--bs-secondary);
  --bs-nav-link-disabled-color: #6c757d;
  --bs-nav-pills-link-active-color: var(--bs-white);
  --bs-nav-pills-link-active-bg: var(--bs-secondary);
  --bs-nav-pills-border-radius: 2.5rem;
}
.nav-pills .nav-item {
  padding: 0.375rem;
}
.nav-pills .nav-link {
  background: var(--bs-nav-pills-link-bg);
}
.submit-info-thank-you {
  font-size: 2rem;
  margin-top: 15rem;
  font-weight: 700;
  margin-bottom: 0rem;
}

.submit-info {
  color: #07223f;
  margin-top: 8rem;
}
.submit-info-heading {
  font-size: 1.875rem;
  text-align: center;
}
.submit-info-text {
  font-size: 1.5rem;
  text-align: center;
}

.submit-info-title {
  color: #07223f;
  font-size: 1.5rem;
  text-align: center;
}
.card.submit-info {
  margin-top: 1.5rem;
  padding: 0.75rem 1.5rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  border: 1px solid #ffffff;
  border-radius: 6.25rem;
}
.card.submit-info .card-text {
  color: #07223f;
  flex: 1 0 0;
}
.card.submit-info .card-action-arrow {
  margin-left: 0.5rem;
}

.card.submit-info .form-title-text {
  color: #07223f;
}
.card.basic-info {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  border: 1px solid #ffffff;
  border-radius: 1.25rem;
}
.card .card-body .basic-info-title {
  font-size: 1.5rem;
  color: #6358dc;
  margin-bottom: 1rem;
}
.card .card-body .form-label {
  font-weight: 600;
  color: #07223f;
}

.youtube-video {
  height: 400px;
  width: 20%;
  padding-left: 10px;
}
.summary-title {
  color: #07223f;
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 1rem;
}
.summary-text {
  color: #6358dc;
  font-size: 1rem;
  font-weight: 600;
  margin-top: 1rem;
}

.experience-content .accordion-body .card.experience-card-body {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  border: 1px solid #ffffff;
  border-radius: 1.25rem;
}
.experience-content .accordion-body .card.experience-card-body .form-label {
  font-weight: 600;
  color: #07223f;
}
.core-skill-text {
  color: #999999;
  margin-left: 0.625rem;
}
.container .card .card-body .or-text {
  color: #6358dc;
  font-weight: 700;
  margin-bottom: 1rem;
  margin-top: 4rem;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
}

/* subscription css */
.subscription-container {
  /*padding: 0.5rem;*/
}
.card.subscription {
  padding: 1.5rem;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  border: 1px solid #ffffff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
  border-radius: 1.25rem;
}
.card.subscription .price-dollar {
  font-size: 3.5rem;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
}
.card-plan {
  height: 100%;
}
.card-plan .card-body {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.card-plan .item-price {
  font-size: 2.5rem;
  font-weight: 900;
}
.card-plan .item-per {
  line-height: 1;
  display: inline-block;
}
.plan-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.plan-list li {
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
}
.plan-list li .item-icon {
  position: absolute;
  left: 0;
  top: calc(0.25rem + 1px);
}

.MuiButtonBase-root.MuiButton-root.MuiButton-text.subscription-dialog-btn {
  background: #f24822;
  color: white;
  text-transform: none;
}

/* Modal CSS */
.modal-bg {
  background-image: url(/src/assets/image/bg-modal.png);
  background-repeat: no-repeat;
  background-size: 100%;
}
.modal-swap-btn {
  background-color: rgba(124, 132, 212, 0.2);
  width: fit-content;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0.625rem 0.5rem;
  border-radius: 0.25rem;
}
.MuiDialogTitle-root.dialog-title {
  background: #07223f;
  color: #ffffff;
}
.dialog-title .btn-close {
  font-size: 1rem;
  background: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23FFF%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  opacity: 1;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}

/* robot help */
.robot-help {
  position: fixed;
  right: 0px;
  bottom: 0px;
  width: 150px;
  z-index: 7;
}
.expCard {
  background-color: #eae8fc;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}

.expCard button {
  background-color: #5d5bc3;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}
.expCard button:hover {
  background-color: #4c4aaa;
}

.career-coaching-container {
  font-family: Arial, sans-serif;
}
.coach-header {
  height: 70px;
  background-color: white;
  align-items: "center";
  padding: "0 20px";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: "0 2px 5px rgba(0,0,0,0.1)";
  z-index: 1000;
}
.coaching-header {
  background-color: #5c4ee5;
  color: white;
  padding: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 70px;
}

.linkedin-btn {
  display: inline-block;
  margin: 20px 0px;
  padding: 24px 15px;
  background-color: white;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.4);
  color: #574ccb;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
}

.coaching-image-placeholder {
  width: 300px;
  height: 300px;
  background-color: #ffb3a7;
}

.text-darkPrimary {
  color: #0a66c2;
}

.linkedin-follow-btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #0077b5;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.7);
  color: white;
  text-decoration: none;
  font-weight: bold;
}
.linkedin-follow-btn:hover {
  color: white;
  transform: scale(1.05);
}

/* Testimonial card for follow screen */
.testimonials {
  background-color: #574ccb;
  padding: 40px 0px;
  text-align: center;
}

.testimonials h3 {
  font-size: 1.8rem;
}

.testimonial-cards {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 75vh;
}

.testimonial-card {
  background-color: #e5e5f5;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.4);
  max-width: 550px;
  text-align: center;
  position: absolute;
  opacity: 0.5;
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.testimonial-card.center {
  transform: scale(1.1);
  opacity: 1;
  z-index: 2;
}

.testimonial-card.left {
  transform: translateX(-150px);
}

.testimonial-card.right {
  transform: translateX(150px);
}

.testimonial-card.hidden {
  display: none;
}

.testimonial-card img {
  border-radius: 50%;
  margin-bottom: 1rem;
}

.testimonial-card p {
  margin: 0;
}

.join-btn {
  display: inline-block;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
  color: var(--bs-primary);
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.7);
  transition: transform 0.3s ease;
}

.join-btn:hover {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .testimonial-card {
    max-width: 400px;
  }
  .testimonial-card.left {
    transform: translateX(-100px);
  }
  .testimonial-card.right {
    transform: translateX(100px);
  }
}

@media (max-width: 981px) {
  .testimonial-cards {
    height: 90vh;
  }
}

@media (max-width: 818px) {
  .testimonial-cards {
    height: 110vh;
  }
}

@media (max-width: 701px) {
  .testimonial-cards {
    height: 160vh;
  }
}

@media (max-width: 614px) {
  .testimonial-cards {
    height: 175vh;
  }
}

@media (max-width: 576px) {
  .testimonial-cards {
    height: auto;
    flex-direction: column;
    gap: 20px;
  }

  .testimonial-card {
    max-width: 90%;
    position: relative;
    opacity: 1;
    transform: none;
    margin-bottom: 1rem;
  }

  .testimonial-card.center,
  .testimonial-card.left,
  .testimonial-card.right {
    transform: none;
    opacity: 1;
    z-index: 1;
  }

  .testimonial-card img {
    width: 100px;
    height: 100px;
  }

  .testimonial-card.left,
  .testimonial-card.right {
    display: block;
  }
}

.app-container {
  font-family: "Arial", sans-serif;
  background-color: #5a4dc4;
}
.linkedin-follow-btn2 {
  display: inline-block;
  padding: 10px 20px;
  background-color: white;
  color: #0077b5;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.7);
  text-align: center;
  text-decoration: none;
  font-weight: bold;
}
.linkedin-follow-btn2:hover {
  color: #0077b5;
  transform: scale(1.05);
}
.mt-n4 {
  margin-top: -4px;
}
.mt-n3 {
  margin-top: -3px;
}
.custom-hr {
  width: 240px;
  margin: 0 auto;
  height: 2px;
  background-color: white;
  opacity: 1;
  border: none;
  margin-top: 20px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.mt-70 {
  margin-top: 70px;
}

/* home page video CSS */
.section-hero {
  padding: 3.75rem 0 3rem;
}
.section-hero .item-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.section-hero .item-list li {
  background-image: url(/src/assets/image/svg/color-checkbox.svg);
  background-repeat: no-repeat;
  background-position: left top;
  background-size: contain;
  padding-left: 3.25rem;
  min-height: 2.25rem;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  color: var(--bs-secondary);
  font-weight: 500;
}
.section-hero .home-video-wrap {
  width: 100%;
  background-image: url(/src/assets/image/home-video-bg.png);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  padding: 3.75rem 13% 6.5rem 0;
}
.section-hero .home-video {
  width: 74%;
  max-width: 100%;
  margin-left: 8.3%;
}
.section-hero .home-video .video-wrap {
  padding-bottom: 65%;
}

.section-our-clients .client-bg {
  padding: 2.75rem 1rem;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
  box-shadow: 0 0.875rem 1.375rem 0 rgba(99, 88, 220, 0.1);
}

.section-our-clients .client-bg .clients-img {
  width: 11.5rem;
  max-height: 3.625rem;
}

.image-container {
  padding-bottom: 1rem;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.image-container-testimonials {
  cursor: pointer;
}
.bg-image-gradiant {
  background: white;
  border-radius: 0.375rem;
}
.profile-img {
  max-width: 37%;
  height: auto;
}
.bg-video-color {
  background-color: #385d83;
}
.template-image {
  height: 160px;
  width: 100%;
  object-fit: "contain";
}
.text-orange {
  color: #f6776e;
}
.text-db {
  color: #0d223dcc;
}
.scroll {
  overflow-x: auto;
  width: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.scroll::-webkit-scrollbar {
  display: none;
}
.card-profile-review {
  width: 400px;
  min-width: 400px;
  background: linear-gradient(
    180deg,
    rgba(178, 162, 220, 0.4) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
}
.left-ms-icon {
  margin-top: 6px;
  margin-left: 6px;
  font-size: 18px;
}
.right-ms-icon {
  margin-top: 6px;
  font-size: 18px;
  margin-right: 2px;
  margin-left: 4px;
}
.btn-carousel {
  padding: 0.125rem 0.25rem;
  background-color: #e6e1f4;
  border: 1px solid;
  border-color: #fff;
}

.start-0 {
  left: -25px !important;
  top: 45%;
}
.end-0 {
  right: -25px !important;
  top: 45%;
}
/* ATS Card */

.ats-heading {
  font-size: 2rem;
  margin-bottom: 48px;
}

.card-light .ats-text {
  cursor: pointer;
  text-decoration: underline;
  display: inline-block;
  animation: jump 1s infinite ease-in-out;
}

@keyframes jump {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.ats-video-wrap {
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.ats-video {
  max-width: 100%;
  width: 800px;
  height: 450px;
  background-color: #000;
  margin: 0 1rem;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  opacity: 0;
  animation: fadeIn 0.3s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.modal-content {
  position: relative;
  background-color: var(--bs-black);
  padding: 20px;
  border-radius: 8px;
  max-width: 800px;
  width: 100%;
  animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
  from {
    transform: translateY(-50px);
  }
  to {
    transform: translateY(0);
  }
}

.close-button {
  position: absolute;
  top: -9px;
  right: -4px;
  font-size: 24px;
  cursor: pointer;
  color: white;
  padding: 3px 10px;
  z-index: 1;
  border-radius: 100%;
  transition: transform 0.5s ease-in-out;
}

.close-button:hover {
  animation: spin 1s linear infinite; /* Infinite spin */
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* blog page CSS */
.card.card-apc .card-body .blog-image {
  border-radius: 1.25rem 1.25rem 0 0;
  width: 100%;
  transition: transform 0.3s;
}
.card.card-apc .card-body .blog-desc-title {
  font-size: 0.75rem;
}
.card.card-apc .card-body .blog-desc-headline {
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 1.125rem;
}
.card.card-apc .card-body .blog-description {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
}
.card.card-apc .card-body .read-more:hover {
  color: #6358dc;
}

.card.card-apc .card-body .image-wrapper {
  position: relative;
  overflow: hidden;
}
.card.card-apc .card-body .image-wrapper .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s;
}
.card.card-apc .card-body .image-wrapper .overlay:hover {
  opacity: 1;
  border-radius: 1.25rem 1.25rem 0 0;
}

@media print {
  @page {
    margin: 2.5rem 3.5rem;
  }
  body {
    background-color: #fff;
  }
  .card {
    box-shadow: none;
  }
  .card-draft {
    --bs-card-spacer-y: 0;
    --bs-card-spacer-x: 0;
  }

  .card-draft .item-header .item-info-row {
    margin: 0 -0.5rem;
  }
  .card-draft .item-header .item-info-row .icon-left {
    padding: 0 0.5rem;
    font-size: 0.875rem;
  }
  .card-draft .item-header .item-info-row .icon-left:not(:last-child) {
    border-right: 2px solid var(--bs-border-color);
  }
  .card-draft .item-header .item-info-row .icon-left .icon {
    font-size: 1rem;
    margin-right: 0.25rem;
  }
}

@media (max-width: 1199px) {
  .section {
    padding: 3rem 0 0;
  }
}
@media (max-width: 992px) {
  .items-we-provide {
    width: 100%;
    padding: 1rem;
  }
  .items-we-provide .item-icon {
    margin-bottom: 1rem;
  }
  .items-we-provide .item-name {
    font-size: 1rem;
  }
}
@media (max-width: 767px) {
  .login-logo {
    max-width: 240px;
  }
  .loader-fill .loader-body .loader-logo img {
    width: 80%;
  }
  .card-login {
    --bs-card-spacer-y: 1.25rem;
    --bs-card-spacer-x: 1.5rem;
  }
  .summary-preview-body .summary-preview-bg {
    padding: 0.5rem;
  }
  .cover-letter-body .cover-letter-preview-bg {
    padding: 0.5rem;
  }

  .section-hero {
    padding: 2rem 0;
  }
  .section-our-clients .client-bg {
    padding: 2rem 1rem;
  }
  .section {
    padding: 2rem 0 0;
  }
  .section-gif-block .gif-content .curly-arrow {
    margin-left: 0;
  }
  .section-we-provide .title-32 {
    font-size: 1.5rem;
  }

  /*.card.submit-info {
    padding: 0;
  }*/

  .card {
    --bs-card-spacer-y: 1.5rem;
    --bs-card-spacer-x: 1.5rem;
    --bs-card-cap-padding-y: 1rem;
    --bs-card-cap-padding-x: 1.5rem;
  }
  .card-login {
    --bs-card-spacer-y: 2.5rem;
    --bs-card-spacer-x: 3rem;
  }
  .card-grd,
  .card-grd.md {
    --bs-card-spacer-y: 1.25rem;
    --bs-card-spacer-x: 1.25rem;
  }
  .card-db-items {
    --bs-card-spacer-y: 2.5rem;
    --bs-card-spacer-x: 2.25rem;
  }
  .card-draft {
    --bs-card-spacer-y: 1rem;
    --bs-card-spacer-x: 1.25rem;
  }
  .card-bordered {
    --bs-card-spacer-y: 0.5rem;
    --bs-card-spacer-x: 0.5rem;
  }
  .card-home {
    --bs-card-spacer-y: 1.5rem;
    --bs-card-spacer-x: 1.25rem;
  }
  .card-apc {
    --bs-card-spacer-y: 1rem;
    --bs-card-spacer-x: 1rem;
  }
  .card-textarea {
    --bs-card-spacer-y: 1.125rem;
    --bs-card-spacer-x: 1.125rem;
  }

  .item-info-row-writer {
    flex-wrap: wrap;
  }
}

@media (max-width: 575px) {
  .items-buttons .MuiButton-root {
    width: 100%;
  }
  .items-buttons .MuiButton-root + .MuiButton-root {
    margin-left: 0;
  }
  .page-pattern-2:before {
    background-size: 65% auto;
    background-position: left 40px;
  }
  .page-pattern-2:after,
  .main-content:after {
    background-size: 65% auto;
  }
  .section-hero .home-video-wrap {
    padding: 2rem 13% 3.5rem 0;
  }
  .video-fixed-wrap .video-modal {
    width: calc(100% - 2rem);
  }
  .card-profile-review {
    width: calc(100vw - 3rem);
    min-width: calc(100vw - 3rem);
  }
}

@media (min-width: 640px) {
  .page-content.page-home.page-home-bg {
    background-size: 30% auto;
  }
  /*.subscription-container {
    padding: 2rem;
  }*/
  .margin-9 {
    margin-bottom: -2.75rem;
  }
}

@media (min-width: 768px) {
  .sidebar-toggler {
    top: 0.85rem;
    right: 5.5rem;
  }
  .page-home-bg {
    padding: 2rem 1.5rem;
  }
  .page-home .section-heading .item-tag {
    font-size: 2rem;
  }
  .page-home .section-heading .item-sub-text {
    font-size: 2rem;
  }
  .page-home .section-heading .item-text {
    font-size: 2rem;
  }
  .page-home .section-heading .item-btn {
    margin-top: 1.875rem;
  }
  .card-home .item-icon img {
    width: 150px;
  }
  .page-templates {
    padding: 2rem 0 2rem;
  }
  .summary-tab {
    position: absolute;
    left: 1.875rem;
    top: 1.875rem;
  }
  .page-subscription {
    padding: 3rem 0;
  }
  .margin-9 {
    margin-bottom: -5rem;
  }
  .card-grd .card-body {
    padding-bottom: 1.25rem;
  }
  .video-fixed-wrap .video-modal {
    width: 50%;
  }
}

@media (min-width: 992px) {
  .navbar-nav {
    --bs-nav-link-font-size: 0.875rem;
  }
  .navbar {
    --bs-navbar-nav-link-padding-x: 0.5rem;
  }
  .main-content {
    padding-left: 254px;
  }
  .sidebar {
    width: 252px;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    padding-top: 80px;
    background-color: #fff !important;
  }
  .sidebar-toggler {
    display: none;
  }
  .offcanvas-backdrop.show {
    opacity: 0;
    /*visibility: hidden;
    width: 0;
    height: 0;
    overflow: hidden;*/
  }
  /*.subscription-container {
    padding: 3rem 6rem;
  }*/
  .section-hero .home-video-wrap {
    padding-top: 4.75rem;
  }
  .video-fixed-wrap .video-modal {
    width: 39.25%;
  }
}

@media (min-width: 1200px) {
  .page-home.bottom-bg {
    background-size: 35% auto;
  }
  .card-home .item-title {
    font-size: 1.75rem;
  }
  .card-home .item-icon img {
    width: 190px;
  }
  .page-templates {
    padding: 2rem 0 2rem;
  }
  .navbar-nav {
    --bs-nav-link-font-size: 1rem;
  }
  /*.subscription-container {
    padding: 3rem 12rem;
  }*/
  .section-hero .home-video-wrap {
    padding-top: 4.375rem;
  }
  .video-fixed-wrap .video-modal {
    width: 32.333%;
  }
}

@media (min-width: 1440px) {
  .card-home .item-title {
    font-size: 2rem;
  }
  .page-home.bottom-bg {
    background-size: 30% auto;
  }
  .page-subscription {
    padding: 4rem 0;
  }
  .section-hero .home-video-wrap {
    padding-top: 4rem;
  }
}
@media (min-width: 1600px) {
  .container-xxxl {
    max-width: 1524px;
  }
  .section-hero .home-video-wrap {
    padding-top: 3.75rem;
  }
}
@media (min-width: 1920px) {
}

@keyframes animate {
  0% {
    background-position: 0 0;
  }
  0% {
    background-position: 400% 0;
  }
}

.error-message {
  color: red;
}

#month-start,
#month-end {
  box-sizing: content-box;
  height: 39px;
  width: 47.49%;
  margin-right: 2%;
  border: 1px solid #dedede;
  border-radius: 0.25rem;
}

.userRoles {
  border: 1px solid var(--bs-border-color);
  border-radius: 0.25rem;
}

.userKeyword {
  margin-top: 10px;
}

.pointer {
  cursor: pointer;
}

/*naisargi css */
/* .MuiDialog-container {
  height: auto !important;
  outline: 0;
}

.MuiDialog-root{
  position: fixed;
    z-index: 0 !important;
    inset: 0px;
    top: 80px !important;
overflow: auto;
} */
/* .MuiDialog-paperWidthSm {
  max-width: 100% !important;
}
.MuiDialog-paper {
   margin: 0px !important;
}
.MuiDialog-paperScrollPaper {
  display: flex;
  max-height: max-content !important;
} */
/* body{
  padding: 0 !important;
} */

.btn-large {
  padding: 0.5rem 3rem !important;
}
.text-bold {
  font-weight: 700;
}
/* @import url('../../src/assets/fonts/Work_Sans/static/WorkSans-Bold.ttf'); */

.price {
  font-size: 40px;
  font-weight: 900;
  /* font-family: 'Work Sans'; */
}
.month {
  display: flex;
  align-items: flex-end;
  padding-left: 0;
  text-align: left;
}
.pr-0 {
  padding-right: 0;
}
.pr-10 {
  padding-right: 40px;
}

.ml-2 {
  margin-left: 8px;
}
.ml-3 {
  margin-left: 12px;
}
.ml-4 {
  margin-left: 16px;
}
.ml-5 {
  margin-left: 20px;
}
.ml-6 {
  margin-left: 24px;
}

.text-femalesandfinance {
  color: #cd007a;
}

.mr-1 {
  margin-right: 4px;
}
.mr-2 {
  margin-right: 8px;
}
.mr-3 {
  margin-right: 12px;
}
.mr-4 {
  margin-right: 16px;
}
.mb-2 {
  margin-bottom: 8px;
}
.mb-3 {
  margin-bottom: 12px;
}
.mb-4 {
  margin-bottom: 16px;
}

.pl-1 {
  margin-left: 4px;
}
.pl-2 {
  margin-left: 8px;
}
.pl-3 {
  margin-left: 12px;
}
.pl-3 {
  margin-left: 16px;
}
.pl-10 {
  margin-left: 40px;
}

.text-hint {
  font-size: 14px;
}

/* .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
  height: none !important;
} */

.pagination {
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background: white;
}

.pagination li {
  border-radius: 1rem;
  margin: 0 0.5rem;
  padding: 5px 6px 4px 6px;
  text-transform: capitalize;
  font-size: 23px;
}

.pagination li.active {
  background: var(--bs-primary);
  color: white;
}

.text-blue {
  color: #3f51b5;
}

.btn-danger {
  background-color: red;
  color: white;
  border: 1px solid red;
  padding: 8px 20px;
  border-radius: 5px;
}

/* carousel css for template */

.btn-carousel-secondary {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  border: none;
}
.bg-gray {
  background: linear-gradient(211.59deg, #f9fafc 2.48%, #0e4fb9 790.99%);
  padding: 1rem;
  border-radius: 100%;
  font-weight: 800;
}

.btn-carousel-secondary:first-child {
  left: 0px;
}

.btn-carousel-secondary:last-child {
  right: 0px;
}

.text-lightGray {
  color: #545454;
  font-family: "Excon";
}
.title-lightGray {
  color: #545454;
  font-family: "Excon-Bold";
}

.resume-preview {
  width: 100%;
  max-width: 450px;
  transition: transform 0.5s, opacity 0.5s;
  margin: 0 auto;
}

.main-preview {
  z-index: 2;
  opacity: 1;
}
.background-preview {
  opacity: 0.5;
}
.carousel-bg-none {
  background-color: #f0f2f9;
  border: white 1px solid;
}

.hover-container {
  position: relative;
}

.hover-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
  transition: opacity 0.3s ease;
  padding: 0.5rem 1rem;
  background-color: var(--bs-primary);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

@media (width >= 1576px) {
  .resume-preview {
    max-width: 500px;
  }
}
@media (width <= 1280px) {
  .resume-preview {
    max-width: 550px;
  }
}
@media (width <= 1147px) {
  .resume-preview {
    max-width: 460px;
  }
}
@media (width <= 1070px) {
  .resume-preview {
    max-width: 340px;
  }
}
@media (width <= 912px) {
  .resume-preview {
    max-width: 620px;
  }

  .text-content {
    margin-top: auto;
    width: 100%;
    text-align: center;
  }
}
@media (width <= 768px) {
  .resume-preview {
    max-width: 420px;
  }
}
@media (width <= 697px) {
  .resume-preview {
    max-width: 320px;
  }
}
@media (width <= 597px) {
  .resume-preview {
    max-width: 250px;
  }
}
@media (width <= 530px) {
  .resume-preview {
    max-width: 150px;
  }
}
@media (width <= 473px) {
  .resume-preview {
    max-width: 70px;
  }
}
@media (width <= 380px) {
  .resume-preview {
    max-width: 100px;
  }
}

/* Vertical header */

.vertical-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  background-color: transparent;
  width: 200px;
}

.vertical-header ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.vertical-header li {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 2rem;
  font-size: 1rem;
}

.vertical-header .indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #ddd;
  margin-right: 10px;
  font-size: 12px;
  color: #fff;
  background-color: transparent;
}

.vertical-header li.active .indicator {
  border-color: #8e8dea;
  border-width: 5px;
  background-color: #fff;
}

.vertical-header li.visited .indicator {
  border-color: #6b60f1;
  background-color: #6b60f1;
  content: "✓";
}

.vertical-header li.inactive .indicator {
  border-color: #8e8dea;
  background-color: white;
}

.vertical-header li.active a {
  font-weight: bold;
  color: #000;
}

.vertical-header li.visited a {
  color: #000;
}

.vertical-header li.inactive a {
  color: #999;
}

.vertical-header li a {
  text-decoration: none;
  color: inherit;
}

.dotted-line {
  position: absolute;
  left: 8px;
  top: 24px;
  height: 28px;
  border-left: 2px dotted #8e8dea;
  z-index: -1;
}

.vertical-header li.visited ~ .dotted-line {
  border-left-color: #8e8dea;
}

.resume-preview {
  left: 50%;
  transform: translateX(-50%) !important;
}

.resume-preview.position-absolute {
  transform: translateX(-50%) scale(1) !important;
}

.resume-preview.position-absolute[style*="translateX(-120px)"] {
  transform: translateX(calc(-50% - 120px)) scale(0.9) !important;
}

.resume-preview.position-absolute[style*="translateX(120px)"] {
  transform: translateX(calc(-50% + 120px)) scale(0.9) !important;
}