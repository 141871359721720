.card-draft .item-name-writer {
  font-size: 2rem;
  line-height: 1.875rem;
  font-weight: 700;
  color: #2d2d2d;
  margin-bottom: 1rem;
  /* text-align: center; */
}

.item-info-row-writer {
  /* display: flex; */
  /* justify-content: center; */
  align-items: center;
  margin: auto;
  padding: auto;
}

.card-draft .item-header-writer .item-info-row-writer .icon-left {
  padding: 0 0.5rem;
  margin: "0px 2px";
  display: inline-flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.card-draft
  .item-header-writer
  .item-info-row-writer
  .icon-left:not(:last-child) {
  border-right: 2px solid var(--bs-border-color);
}

.card-draft .item-header-writer .item-info-row-writer .icon-left .icon {
  margin-right: 0.5rem;
}

.card-draft .item-deg-writer {
  font-size: 1.55rem;
  line-height: 1.375rem;
  font-weight: 700;
  color: #2d2d2d;
  /* margin: 0.5rem 0; */
  padding: 0.5rem 0;
  /* text-align: center; */
  /* border-top: 3px solid black; */
  border-bottom: 2px solid var(--bs-border-color);
}

.card-draft .item-header-writer {
  padding-bottom: 0.5rem;
}

.card-draft .item-title-writer {
  font-size: 1.5rem;
  line-height: 1.75rem;
  font-weight: 500;
  color: #2d2d2d;
  margin-bottom: 0.25rem;
  padding: 0.125rem 0rem;
  /* text-align: center; */
  text-decoration: underline;
}

.items-data-writer .item-repeats {
  padding: "4px 18px";
  display: flex;
  flex-direction: column;
  align-items: center;
}

.items-data-writer .item-repeats + .item-repeats {
  margin-top: 0.25rem;
}

.items-data-writer .item-desc {
  margin-top: 1rem;
  padding: 0 0.625rem;
}

.items-data-writer .item-repeats .h6 {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: #000000;
}

.item-info-projects,
.item-info-certifications,
.item-info-education {
  display: inline-flex;
}

.writer-ele-edit-wrap {
  position: relative;
}

.card-draft:hover .writer-ele-edit-wrap:before {
  content: "";
  position: absolute;
  left: 0;
  top: -0.25rem;
  right: 0;
  bottom: -0.25rem;
  border: 1px dashed #aaa;
  pointer-events: none;
}

.writer-ele-edit-wrap > .ele-edit {
  position: absolute;
  left: -2rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  background: var(--bs-border-color);
  padding: 0.25rem;
  border-radius: 0.125rem;
}

.item-data-writer {
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
}
.item-repeats-writer {
  display: flex;
  /* margin-top: -0.625rem; */
  /* padding: 0 2.5rem; */
  justify-content: flex-start;
}

.item-skill-writer {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  padding-left: 1rem;
}
.item-skill-writer .skill-data-writer {
  flex-basis: max-content;
  display: flex;
  padding: 0%;
}
.item-skill-writer .skill-data-writer:last-child .item-list {
  content: "•";
  margin-left: 20px;
}
.skill-data-writer:last-child::after {
  content: "•";
  margin-left: 2px;
}

.item-list-writer {
  align-items: center;
  margin-bottom: 4px;
  padding-right: 4px;
}
.item-list-writer::before {
  content: "•";
  margin-right: 4px;
}
