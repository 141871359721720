.amit-card-container {
  /* width: 100%; */
  background-color: #f4f8ff;
  border-radius: 10px;
  padding: 20px;
  padding-left: 10px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.amit-background-left-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 400px;
  height: 420px;
  background: url("../assets/image/bottom_left.png") no-repeat;
  background-size: cover;
  z-index: 0;
}

.amit-background-right-top {
  position: absolute;
  top: 0;
  right: 0;
  width: 200px;
  height: 200px;
  background: url("../assets/image/top_right.png") no-repeat;
  background-size: cover;
  z-index: 0;
}

.amit-main-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.amit-image {
  z-index: 2;
}
.amit-profile-pic {
  border-radius: 100%;
  border: 2px solid #ffffff;
  width: auto;
  z-index: 1;
}
.amit-main-content .amit-image h1 {
  font-size: 20px;
  color: #fff;
  z-index: 2;
  font-weight: 800;
  text-align: center;
}

.amit-card-container h1 {
  font-size: 20px;
  color: #000000;
  z-index: 1;
  font-weight: 800;
  text-align: center;
}

.amit-profile-info ul {
  /* padding: 0; */
  z-index: 1;
}

.amit-profile-info ul li {
  margin-bottom: 10px;
  font-size: 14px;
  color: #153d82;
  font-weight: 600;
  z-index: 1;
  text-align: start;
}

.amit-profile-info ul li::before {
  color: #153d82;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.amit-contact-info {
  text-align: right;
  margin-top: 20px;
}

.amit-contact-info a {
  display: inline-block;
  color: #ffffff;
  padding: 10px 15px;
  text-decoration: none;
  font-weight: bold;
}

.amit-contact-info p {
  margin: 5px 0;
  color: #424242;
}

.amit-social-icons {
  margin-top: 10px;
}

.amit-social-icons i {
  margin: 0 5px;
  color: #6a1b9a;
}

/* Responsive Adjustments */
@media (max-width: 1375px) {
  .amit-background-left-bottom {
    width: 370px;
    height: 390px;
    z-index: 0;
  }

  .amit-background-right-top {
    width: 180px;
    height: 180px;
    z-index: 0;
  }
}
@media (max-width: 1210px) {
  .amit-background-left-bottom {
    width: 350px;
    height: 380px;
    z-index: -1;
  }

  .amit-background-right-top {
    width: 180px;
    height: 180px;
    z-index: 0;
  }
}

@media (max-width: 768px) {
  .amit-card-container {
    padding: 10px;
  }

  .amit-main-content {
    flex-direction: column;
    align-items: center;
  }

  .amit-profile-info {
    margin-left: 0;
    text-align: center;
  }

  .amit-contact-info {
    text-align: center;
  }
}

@media (max-width: 430px) {
  .amit-background-left-bottom {
    width: 350px;
    height: 380px;
    z-index: -1;
    display: none;
  }

  .amit-background-right-top {
    width: 150px;
    height: 150px;
    z-index: -1;
  }
  .amit-main-content .amit-image h1 {
    color: #000;
    font-size: 20px;
    z-index: 2;
    font-weight: 800;
  }
}
